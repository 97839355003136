export const priceRange = (t) => {return [
    {
      min: 0,
      max: 1000,
      label: t('neatcover:zeroToOneThousand')
    },
    {
      min: 1001,
      max: 2000,
      label: t('neatcover:oneThousandToTwoThousands')
    },
    {
      min: 2001,
      max: 3000,
      label: t('neatcover:twoToThreeThousands')
    },
    {
      min: 3001,
      max: 99994000,
      label: t('neatcover:threeThousandsPlus')
    }
  ]}

export const findPriceRange = (price, t) => {
    return priceRange(t).find(threshold => price >= threshold.min &&price <= threshold.max);
}