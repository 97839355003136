import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getRolesData, signIn, signUp } from '../../store/actions/User';
import NeatcarButton from "../../components/NeatcarButton";
import neatcarLogo from '../../assets/images/neatcar-logo.png';
import GearLoadingIndicator from "../../components/GearLoadingIndicator";

const SignUp = () => {
  const form = useForm();
  const { register, handleSubmit, formState: { errors }, getValues, watch } = form;
  const [loginError, setLoginError] = useState(false);
  const [passwordErrors, setPasswordErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('insurerCreation');

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { user, authenticated, rolesLoading, roles ,error } = useSelector(store => store.session);

  useEffect(() => {
    setLoading(false);
    if (error) {
      setLoginError(true);
    } else {
      if (authenticated) {
        // Dispatch getRolesData and wait for it to complete
        dispatch(getRolesData());
      }

      if (authenticated && roles && roles.length > 0) {
        const defaultRoute = roles.find(role => role.role === "ROLE_BUSINESS") ? "/team" : "/Insurer";
        const from = location.state?.from?.pathname || defaultRoute;
        navigate(from, { replace: true });
      } 
    }
  }, [authenticated,user, error, dispatch, location, navigate, roles]);


  const login = (data) => {
    setLoading(true);
    setLoginError(false);
    dispatch(signIn(data.email, data.password, data.rememberMe));
  }

  const handleSignUp = (data) => {
    
    if (data) {
      if (data.password !== data.confirmPassword) {
        setPasswordErrors(true);
      } else {
        setLoading(true);
        dispatch(signUp(data.email, data.password, data.firstName, data.lastName, true));
      }
    }
    
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={neatcarLogo}
            alt="Workflow"
          />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className={'text-center py-4 mb-4'}>
            {loading && (<div className="flex justify-center items-center w-full h-16 flex-col">
              <GearLoadingIndicator />
            </div>)}
              <h1 className={'text-3xl font-bold leading-7'}>{t('title')}</h1>
              <h3 className={'text-sm text-gray-400 mt-2'}>{t('subtitle')}</h3>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit(handleSignUp)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t('email')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("email", { required: { value: true, message: t('required') }, pattern: { value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i, message: t('wrongEmail') } })}
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  <span className="error">{errors.email?.message}</span>
                </div>
              </div>

              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                  {t('firstName')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("firstName", { required: { value: true, message: t('required') } })}
                    id="firstName"
                    name="firstName"
                    type="text"
                    autoComplete="firstName"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  <span className="error">{errors.firstName?.message}</span>
                </div>
              </div>

              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                  {t('lastName')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("lastName", { required: { value: true, message: t('required') } })}
                    id="lastName"
                    name="lastName"
                    type="text"
                    autoComplete="lastName"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  <span className="error">{errors.lastName?.message}</span>
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t('password')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("password", { required: { value: true, message: t('required') } })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  {!loginError && (<span
                    className="error">{errors.password?.message}</span>
                  )}
                </div>
              </div>

              <div>
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                  {t('confirmPassword')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("confirmPassword", { required: { value: true, message: t('required') } })}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    autoComplete="current-password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  {!loginError && (<span
                    className="error">{errors.confirmPassword?.message}</span>
                  )}
                  {passwordErrors && (<span
                      className="error">{t('passwordDoesNotMatch')}</span>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">

                <div className="text-sm">
                  {/*<a href="#top" className="font-medium text-indigo-600 hover:text-indigo-500">*/}
                  {/*  {t('forgotPassword')}*/}
                  {/*</a>*/}
                </div>
              </div>

              <div>
                <NeatcarButton
                  type={'submit'}
                  loading={loading}
                  title={t('signUp')}
                />
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  )
}

export default SignUp;
