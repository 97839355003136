import * as React from "react";
import CreditCardInput from "../../components/CreditCardInput/CreditCardInput";

const Profile = () => {

  
  return (
    <div className="flex-1 flex-row flex w-full h-full">
      <CreditCardInput />
    </div>  
  )
};

export default Profile;
