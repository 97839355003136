import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { getRolesData, signIn } from '../../store/actions/User';
import NeatcarButton from "../../components/NeatcarButton";
import neatcarLogo from '../../assets/images/neatcar-logo.png';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('login');

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const { user, authenticated, rolesLoading, roles ,error } = useSelector(store => store.session);

  useEffect(() => {
    setLoading(false);
    if (error) {
      setLoginError(true);
    } else {
      if (authenticated) {
        // Dispatch getRolesData and wait for it to complete
        dispatch(getRolesData());
      }

      if (authenticated && roles && roles.length > 0) {
        const defaultRoute = roles.find(role => role.role === "ROLE_BUSINESS") ? "/team" : "/Insurer";
        const from = location.state?.from?.pathname || defaultRoute;
        navigate(from, { replace: true });
      } 
    }
  }, [authenticated,user, error, dispatch, location, navigate, roles]);

  useEffect(()=>{

    if(roles && rolesLoading == false){

    }


  },[rolesLoading])

  const onSubmit = (data) => {
    setLoading(true);
    setLoginError(false);
    dispatch(signIn(data.email, data.password, data.rememberMe));
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src={neatcarLogo}
            alt="Workflow"
          />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className={'text-center py-4 mb-4'}>
              <h1 className={'text-3xl font-bold '}>{t('title')}</h1>
              <h3 className={'text-sm text-gray-400'}>{t('subtitle')} <a className={'text-neatcar'} href={t('salesLink')} target={'_blank'} rel="noreferrer">{t('salesLinkLabel')}</a></h3>
            </div>
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  {t('email')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("email", { required: { value: true, message: t('required') }, pattern: { value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i, message: t('wrongEmail') } })}
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  <span className="error">{errors.email?.message}</span>
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t('password')}
                </label>
                <div className="mt-1">
                  <input
                    {...register("password", { required: { value: true, message: t('required') } })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-neatcar focus:border-neatcar sm:text-sm"
                  />
                  {!loginError && (<span
                    className="error">{errors.password?.message}</span>
                  )}
                  {loginError && (<span
                      className="error">{t('wrongCredentials')}</span>
                  )}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    {...register('rememberMe')}
                    id="rememberMe"
                    name="rememberMe"
                    type="checkbox"
                    className="h-4 w-4 text-neatcar focus:ring-neatcar border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    {t('rememberMe')}
                  </label>
                </div>

                <div className="text-sm">
                  {/*<a href="#top" className="font-medium text-indigo-600 hover:text-indigo-500">*/}
                  {/*  {t('forgotPassword')}*/}
                  {/*</a>*/}
                </div>
              </div>

              <div>
                <NeatcarButton
                  type={'submit'}
                  loading={loading}
                  title={t('signIn')}
                />
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  )
}

export default Login;
