import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentCard, setCreditCardLoading } from "../../store/actions/Business";
import { pushNotification } from "../../store/actions/Notifications";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ERROR, Notification, SUCCESS } from "../../models/Notification";
import InputMask from "react-input-mask";
import visa from '../../assets/images/visa.png';
import mastercard from '../../assets/images/mastercard.png';
import NeatcarButton from "../../components/NeatcarButton";
import { updateCard } from "../../services/business";

export default function CreditCardInput() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { currentCard, cardLoading } = useSelector(store => store.business);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getCurrentCard());
    }, [dispatch]);
  
    const onSubmit = (data) => {
      dispatch(setCreditCardLoading(true));
      updateCard({
        name: data.name,
        number: data.cardNumber.replace(/\s/g, ''),
        exp_month: data.expirationDate.substring(0, 2),
        exp_year: `20${data.expirationDate.substring(3, 5)}`,
        cvv: data.cvv
      }).subscribe({
        next: () => {
          dispatch(getCurrentCard());
          dispatch(pushNotification(new Notification(SUCCESS, t('billing:modifiedCreditCardSuccessTitle'), t('billing:modifiedCreditCardSuccessMessage'))));
        },
        error: (err) => {
          dispatch(setCreditCardLoading(false));
          dispatch(pushNotification(new Notification(ERROR, t('billing:modifiedCreditCardErrorTitle'), t('billing:modifiedCreditCardErrorMessage'))));
        }
      });
    }
  
    const { t } = useTranslation('profile');

    return (
        <div className="bg-white rounded-lg border-r border-gray-200 px-5 py-6 sm:px-6 h-fit" style={{ width: '24rem' }}>
        <div className="pb-5 border-b border-gray-200 sm:flex flex-row sm:items-center sm:justify-between">
          <div className="whitespace-nowrap">
            <span
              className="text-lg leading-6 font-medium">{t('billing:paymentMethod')}</span>
          </div>
        </div>

        {currentCard && (<div className="py-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <div className="whitespace-nowrap">
            <span
              className="text-md font-medium">{t('billing:currentCard')}</span>
          </div>
          <div className="flex flex-row items-center text-sm font-bold gap-x-2">
            <img className="h-4" src={currentCard.brand === "Visa" ? visa : mastercard} alt={currentCard.brand}/>
            <div> **** {currentCard.last4}</div>
          </div>
        </div>)}

        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="pt-5 whitespace-nowrap">
            <span
              className="text-md font-medium text-gray-900">{t('billing:updateCard')}</span>
          </div>

          <div className="py-5 sm:flex flex-col">
            <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700">
              {t('cardNumber')}
            </label>
            <div className="mt-1">
              <InputMask
                {...register("cardNumber", { pattern: { value: /[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}/, message: t('invalid') }, required: { value: true, message: t('required') } })}
                mask="9999 9999 9999 9999" maskChar="_"
                type="text"
                name="cardNumber"
                id="cardNumber"
                className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <span className="error">{errors.cardNumber?.message}</span>
          </div>

          <div className="py-5 sm:flex flex-col">
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
              {t('name')}
            </label>
            <div className="mt-1">
              <input
                {...register("name", { required: { value: true, message: t('required') } })}
                type="text"
                name="name"
                id="name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <span className="error">{errors.name?.message}</span>
          </div>

          <div className="py-5 sm:flex flex-row justify-between gap-x-2">
            <div className="flex flex-col flex-1">
              <label htmlFor="expirationDate" className="block text-sm font-medium text-gray-700">
                {t('expirationDate')}
              </label>
              <div>
                <InputMask
                  {...register("expirationDate", { pattern: { value: /(0[1-9]|1[012])\/(2[2-9]{1})/, message: t('invalid') }, required: { value: true, message: t('required') } })}
                  mask="ab/cd" maskChar="_"
                  formatChars={
                    {
                      'a': '[0-1]',
                      'b': '[0-9]',
                      'c': '[2]',
                      'd': '[2-9]',
                    }
                  }
                  type="text"
                  name="expirationDate"
                  id="expirationDate"
                  className="shadow-sm focus:ring-neatcar focus:border-neatcar w-full block sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <span className="error">{errors.expirationDate?.message}</span>
            </div>
            <div className="flex flex-col">
              <label htmlFor="cvv" className="block text-sm font-medium text-gray-700">
                {t('cvv')}
              </label>
              <div className="w-full">
                <InputMask
                  {...register("cvv", { pattern: { value: /[0-9]{3}/, message: t('invalid') }, required: { value: true, message: t('required') }} )}
                  mask="999" maskChar="_"
                  type="text"
                  name="cvv"
                  id="cvv"
                  className="shadow-sm focus:ring-neatcar focus:border-neatcar w-16 block sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <span className="error">{errors.cvv?.message}</span>
            </div>
          </div>


          <div className="mt-4 flex justify-end justify-items-end items-end">
            <div className="w-32">
              <NeatcarButton
                type={'submit'}
                loading={cardLoading}
                title={t('update')}
              />
            </div>
          </div>
        </form>
      </div>
    )
}

