
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { CarDetails } from '../AssuranceRequest/Row';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import GearLoadingIndicator from '../GearLoadingIndicator';
import ClockLoadingIndicator from '../ClockLoadingIndicator';
import Button from '../ButtonGroup/Button';
import { useNavigate } from 'react-router-dom';

export const usePendingAssuranceRoleApproval = () => {
    const { user, roles } = useSelector(store => store.session);
    const [pendingAssuranceRoleApproval, setPendingAssuranceRoleApproval] = useState(roles && roles.find(role => role.role === 'ROLE_ASSURANCE' && role.isAccepted !== true));

    useEffect(() => {
        setPendingAssuranceRoleApproval(roles && roles.find(role => role.role === 'ROLE_ASSURANCE' && role.isAccepted !== true));
    }, [roles]);

    return { pendingAssuranceRoleApproval }
}

export default function PendingAssuranceRoleApproval({ pendingAssuranceRoleApproval }) {
    const { t } = useTranslation('neatcover');
    const navigate = useNavigate();
    const openProfile = () => {
        navigate('/profile');
    }

    return (
        <div className="flex flex-col w-full flex-1 ">
            <div className="w-full h-full flex flex-col items-center justify-center py-32">
                <div className="scale-[3] self-center w-32 h-20 flex flex-col items-center justify-center">
                    <ClockLoadingIndicator />
                </div>
                
                <p className='text-black text-4xl font-bold text-center w-full max-w-3xl mt-8'>
                    {t('pendingAssuranceRoleApproval')}
                </p>
                <p className='text-gray-400 text-xl font-medium text-center w-full max-w-4xl mt-4'>
                    {t('pendingAssuranceRoleApprovalDescription')}
                </p>
                <Button className='mt-8' onClick={openProfile}>
                    {t('openProfile')}
                </Button>
            </div>
        </div>
    )
}
