import { SET_AUTHENTICATED, SET_WRONG_EMAIL_OR_PASSWORD,GET_ROLES,SET_ROLES, LOGOUT } from '../types/Session';
import { authenticate, getCurrentUser,getRoles, logout, createAccount } from "../../services/auth";

export const signUp = (login, password, firstName, lastName, requestAssuranceRole) => {
  return (dispatch) => {

    createAccount(login, password, firstName, lastName, requestAssuranceRole).subscribe({
      next: (newUser) => {
        authenticate(login, password, false).subscribe({
          next: () =>
            getCurrentUser().subscribe({
              next: (user) => dispatch({ type: SET_AUTHENTICATED, payload: user }),
              }),
        });
      }
    });
  };
};

export const signIn = (login, password, rememberMe) => {
  return (dispatch) => {

    const handleError = (error) => {
      dispatch({ type: SET_WRONG_EMAIL_OR_PASSWORD, payload: error });
      dispatch({ type: SET_AUTHENTICATED, payload: undefined });
    }

    authenticate(login, password, rememberMe).subscribe({
      next: () =>
        getCurrentUser().subscribe({
          next: (user) => dispatch({ type: SET_AUTHENTICATED, payload: user }),
          error: handleError
          }),
      error: handleError
    });
  };
};

export const getUser = () => {
  return (dispatch) => {
    getCurrentUser().subscribe({
      next: (user) => dispatch({ type: SET_AUTHENTICATED, payload: user }),
      error: () => dispatch({ type: SET_AUTHENTICATED, payload: undefined })
    });
  };
};

export const getRolesData = () => async (dispatch) => {
  dispatch({ type: GET_ROLES });
  
  return new Promise((resolve, reject) => {
    getRoles().subscribe({
      next: (value) => {
        dispatch({ type: SET_ROLES, payload: value });
        resolve(); // Resolve the promise when data fetching is complete
      },
      error: (err) => {
        reject(err); // Reject the promise if there's an error
      }
    });
  });
}

export const logoutUser = () => (dispatch) => {
  logout().subscribe({
    next: () => dispatch({ type: LOGOUT }),
    error: () => dispatch({ type: LOGOUT })
  });
}
