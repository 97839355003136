const fr = {
  login: {
    title: 'Compte entreprise',
    subtitle: 'Pas de compte entreprise ?',
    salesLinkLabel: 'Contactez nous.',
    salesLink: 'https://neatcar.com/contact.php',
    email: 'Courriel',
    password: 'Mot de passe',
    rememberMe: 'Se souvenir de moi',
    forgotPassword: 'Mot de passe oublié ?',
    signIn: 'Se connecter',
    wrongCredentials: 'Les identifiants ne sont pas valides',
    required: 'Ce champ est requis',
    wrongEmail: 'Ce courriel n\'est pas valide',
  },
  notFound: {
    error: 'Erreur 404',
    lost: 'Uh oh! Vous avez l\'air perdu.',
    subtitle: 'Il semble que la page que vous recherchez n\'existe pas.',
    goBackHome: 'Revenir à l\'accueil',
  },
  menu: {
    fleat: 'Flotte & Équipe',
    team: 'Équipe',
    billing: 'Facturation',
    otherLanguage: 'English',
    otherLanguageKey: 'en',
    logout: 'Déconnexion',
    marketplace: 'Marketplace',
    marketplaceGiftTitle: 'Cadeau et expérience',
    marketplaceGiftDesc: 'Offrez l\'abonnement Neatcar ou des lavages en cadeau à des clients tout en personnalisant l\'expérience.',
    marketplaceDealershipTitle: 'Concessionnaire',
    marketplaceDealershipDesc: 'Module complet pour personnaliser l\'expérience des concessionnaires automobiles.',
    marketplaceStationTitle: 'Station de lavage',
    marketplaceStationDesc: 'Ajouter votre station de lavage dans Neatcar et rejoingez des milliers d\'automobilistes facilement.',
    marketplaceAdCampaignTitle: 'Campagnes publicitaires',
    marketplaceAdCampaignDesc: 'Les automobilistes sont votre clientèle ? Rejoignez-les au bon moment.',
    marketplaceSalesTitle: 'Souhaitez-vous parler aux ventes ?',
    marketplaceSalesDesc: 'Écrivez-nous : ventes@neatcar.com',
    bidPendingUserApproval: 'Offre en attente de validation',
    bidApprovedByUser: 'Offre approuvée',
    discarded: 'Demandes écartées',
    new: 'Nouveaux',
    profile: 'Profil',
    Insurer: 'Courtier'
  },
  teams: {
    addMember: 'Ajouter un membre',
    email: 'Courriel',
    or: 'Ou',
    userId: 'User ID',
    userIdHelp: 'Le user ID est affiché dans la page profil de l\'application mobile.',
    addToTeam: 'Ajouter à l\'équipe',
    cancel: 'Annuler',
    empty: 'Vous devez saisir un courriel ou un user ID',
    errors: {
      11: 'Assurez-vous d’entrer un courriel ou ID valide.',
      25: 'Cet utilisateur ne peut pas être ajouté car il est déjà dans votre équipe.',
      26: 'Vous avez atteint le montant maximum d\'employés pour votre plan. Contactez-nous à ventes@neatcar.com si vous souhaitez mettre à jour votre forfait.'
    },
    addSuccessTitle: '{{firstName}} a bien été ajouté',
    addSuccessMessage: '{{firstName}} pourra maintenant acheter des codes de lavage selon ses permissions.'
  },
  member: {
    delete: 'Supprimer',
    deleteTitle: 'Retirer ce compte',
    deleteText: 'Voulez-vous vraiment supprimer ce compte ? Toutes les données seront définitivement supprimées de nos serveurs pour toujours. Cette action ne peut pas être annulée.',
    cancel: 'Annuler',
    maxCarwashPerMonth: 'Nombre limite de lavages par mois',
    startHour: 'Heure minimale',
    endHour: 'Heure maximale',
    days: 'Journées',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
    sunday: 'Dimanche',
    update: 'Mettre à jour',
    permissions: 'Permissions'
  },
  transaction: {
    transactions: 'Transactions',
    price: '{{price}}$',
    paidByCard: 'Payé par carte **** {{digits}}',
    download: 'Télécharger',
    export: 'Exporter CSV',
    noTransactions: 'Aucune transaction'
  },
  billing: {
    paymentMethod: 'Méthode de paiement',
    update: 'Mettre à jour',
    cardNumber: 'Numéro de carte',
    name: 'Nom sur la carte',
    expirationDate: 'Date d\'expiration (MM/AA)',
    cvv: 'CVV',
    currentCard: 'Carte de crédit actuelle',
    updateCard: 'Mettre à jour la carte',
    required: 'Champ obligatoire',
    invalid: 'Champ invalide',
    modifiedCreditCardSuccessTitle: 'Carte de crédit mise à jour',
    modifiedCreditCardSuccessMessage: 'Votre carte de crédit a bien été mise à jour.',
    modifiedCreditCardErrorTitle: 'Erreur de mise à jour',
    modifiedCreditCardErrorMessage: 'Votre carte de crédit n\'a pas pu être mise à jour.'
  },
  insurer: {
    newInsurance: 'Nouvelle assurance',
    filters: 'Filtres',
    renewalMonth: 'Mois de renouvellement',
    reset: 'Réinitialiser',
    cancel: 'Annuler',
    apply: 'Appliquer',
    motorOption: 'Option moteur',
    all: 'Tout',
    electric: 'Électrique',
    gas: 'Gaz',
    priceRange: 'Fourchette de prix',
    carMake: 'Marque de voiture',
    commercial: 'Commercial',
    personal: 'Personnel',
    licenseType: 'Type de licence',
    creditScore: 'Score de crédit',
    creditUnknown: 'Inconnu',
    creditPoor: 'Faible (300-579)',
    creditFair: 'Moyen (580-669)',
    creditGood: 'Bon (670-739)',
    creditVeryGood: 'Très bon (740-799)',
    creditExcellent: 'Excellent (800-900)',
    tickets: 'Tickets',
    withTicket: 'Avec ticket',
    withoutTicket: 'Sans ticket',
    accidents: 'Accidents',
    withAccident: 'Avec accident',
    withoutAccident: 'Sans accident',
    age: 'Âge du conducteur',
    equals: 'Égal à',
    lessThan: 'Moins que',
    moreThan: 'Plus que',
    carYear: 'Année de voiture',
    filtersApplied: 'appliqués',
    discard: 'Écarter',
    car: 'Voiture',
    offer: 'Offre',
    user: 'Utilisateur',
    amount: 'Montant',
    date: 'Date',
    status: 'Statut',
    offerSent: 'Offre envoyée',
    insuranceOfferDetail: 'Détails de l\'offre d\'assurance',
    accepted: 'Acceptée',
    pending: 'En attente',
    deleteOffer: 'Supprimer l\'offre',
    sendOffer: 'Envoyer l\'offre',
    driverInformation: 'Informations du conducteur',
    driver: 'Conducteur',
    fullName: 'Nom complet',
    address: 'Adresse',
    addressSince: 'Adresse depuis',
    licenseNumber: 'Permis de conduire',
    yearGotLicense: 'Age d\'obtention de licence',
    birthday: 'Date de naissance',
    carLicense: 'Type de permis',
    isWithCurrentInsurerSince: 'Avec assurance depuis',
    credit: 'Score de crédit',
    currentInsurer: 'Assureur actuel',
    insuranceRenewalMonth: 'Mois de renouvellement',
    isInsuredSince: 'Avec assurance depuis',
    isOpenToHomeInsurance: 'Ouvert à l\'assurance habitation',
    gender: 'Genre',
    weekOutOfState: 'Semaine hors province',
    verificationProfile: 'Vérification du profil',
    discount: 'Rabais',
    carInformation: 'Informations sur la voiture',
    vin: 'VIN',
    currentPriceAnnually: 'Prix actuel annuel',
    damageToOther: 'Dommages à autres',
    riskToOther: 'Risque à autres',
    collision: 'Collision',
    paymentSchedule: 'Période de paiement',
    allstateInsurer: "Allstate",
    alphaInsuranceInsurer: "ALPHA Assurances",
    avivaInsurer: "Aviva",
    belairDirectInsurer: "Belairdirect",
    benevaInsurer: "Beneva",
    caaQuebecInsurer: "CAA",
    cibcInsuranceInsurer: "CIBC",
    coOperatorsInsurer: "Co-operators",
    desjardinsInsurer: "Desjardins",
    economicalInsuranceInsurer: "Economical",
    iaFinancialGroupInsurer: "iA Groupe Financier",
    intactInsuranceInsurer: "Intact",
    laPersonnelleInsurer: "La Personnelle",
    nationalBankInsuranceInsurer: "Banque Nationale",
    promutuelAssuranceInsurer: "Promutuel",
    rbcInsuranceInsurer: "RBC",
    scotiaLifeFinancialInsurer: "Scotia Assurance",
    sonnetInsurer: "Sonnet",
    tdInsuranceInsurer: "TD Assurance",
    wawanesaInsurer: "Wawanesa",
    otherInsurer: "Autre",
    noAssurance: "Aucune assurance",
    lessThan1Year: "Moins de 1 an",
    "1Year": "1 an",
    "2Years": "2 ans",
    "3Years": "3 ans",
    "4Years": "4 ans",
    "5YearsOrMore": "5 ans ou plus",
    yes: "Oui",
    no: "Non",
    male: "Homme",
    female: "Femme",
    other: "Autre",
    never: "Jamais",
    lessThan3Weeks: "Moins de 3 semaines",
    "4to7Weeks": "4 à 7 semaines",
    "8to15Weeks": "8 à 15 semaines",
    "16to26Weeks": "16 à 26 semaines",
    "moreThan26Weeks": "Plus de 26 semaines",
    none: "Aucun",
    student: "Étudiant",
    retired: "Retraité",
    maried: "Marié",
    fullTimeWork: "Salarié à temps plein",
    universityDegree: "Diplôme universitaire",
    hitAndRunExemption: "Exemption pour accident de voiture",
    newReplacement: "Remplacement neuf",
    accidentForgiveness: "Pardon d'accident",
    purchaseAmount: "Montant d'achat",
    antitheft: "Antivol",
    boughtState: "État d'achat",
    franchise: "Franchise",
    kilometerDaily: "Kilométrage quotidien",
    interest: "Intérêt",
    boughtType: "Type d'achat",
    insurerCancelationNumberInLast5Years: "Nombre de résiliation d'assurance dans les 5 dernières années",
    colision: "Collision",
    franchiseExemptionHitAndRun: "Exemption franchise pour hit & run",
    newReplacement: "Remplacement neuf",
    financeDuration: "Durée de financement",
    boughtDate: "Date d'achat",
    civilResponsability: "Responsabilité civile",
    kilometerAnnually: "Kilométrage annuel",
    usage: "Usage",
    newCar: "Neuf",
    usedCar: "Occasion",
    demoCar: "Démonstration",
    paid: "Totalement payé",
    financed: "Financé",
    location: "Loué",
    "1": "1",
    "2OrMore": "2 ou plus",
    work: "Travail",
    school: "École",
    drivingPleasure: "Plaisir",
    red: "Rouge",
    green: "Vert",
    blue: "Bleu",
    white: "Blanc",
    black: "Noir",
    gray: "Gris",
    orange: "Orange",
    yellow: "Jaune",
    carDetails: "Détails de la voiture",
    homeDetails: "Détails de la maison",
    userSaidTheyHaveTicketsButDidNotProvideAnyInformation: "L'utilisateur a indiqué qu'il avait des tickets mais n'a pas fourni d'informations",
    userSaidTheyHaveAccidentsButDidNotProvideAnyInformation: "L'utilisateur a indiqué qu'il avait des accidents mais n'a pas fourni d'informations",
    explanation: "Explication",
    date: "Date",
    accident: "Accident",
    sherlock: "Sherlock",
    tag: "Tag",
    alarm: "Alarme",
    noAntiTheft: "Aucun système",
    "2-weeks": "2 semaines",
    monthlyAmount: "Mensuel",
    annualAmount: "Annuel",
    dangerousMatterUsed: "Est utilisé pour transporter des matières dangereuses.",
    contestUsed: "Est modifié, reconstruit ou utilisé pour des compétitions, expositions ou courses.",
    registeredOutOfState: "Est enregistré hors de mon état ou géré loin de mon adresse personnelle.",
    UberOrTuro: "Est utilisé pour les courses (e.g., Uber) ou le leasing (e.g., Turo)",
    claimWaiting: "A des réclamations en attente, en cours ou en attente de réparation.",
    criminalRecord: "A un dossier judiciaire ou une procédure en cours contre vous.",
    medicalCondition: "A des conditions médicales qui affectent leur conduite.",
    licenseRevoked: "A eu son permis de conduire suspendu ou révoqué dans les 5 dernières années.",
    bankruptcy: "A déposé une demande de faillite. (Une faillite annulée depuis 3 ans ou plus n'est pas considérée.)",
    insurerRefused: "A été refusé une assurance ou avoir son assurance annulée. Un refus de renouvellement est considéré.",
  },
  insurerCreation:{
    title: "Créer votre compte courtier",
    subtitle: "Créez votre compte courtier maintenant et nous le vérifierons dans les 48 heures.",
    email: "Courriel",
    password: "Mot de passe",
    rememberMe: "Se souvenir de moi",
    signUp: "S'inscrire",
    firstName: "Prénom",
    lastName: "Nom",
    confirmPassword: "Confirmer le mot de passe",
    requestAssuranceRole: "Demande de rôle de courtier",
    passwordDoesNotMatch: "Le mot de passe et la confirmation du mot de passe ne correspondent pas.",
    passwordRequired: "Le mot de passe est requis",
    firstNameRequired: "Le prénom est requis",
    lastNameRequired: "Le nom est requis",
    emailRequired: "Le courriel est requis",
    confirmPasswordRequired: "La confirmation du mot de passe est requise",
    requestAssuranceRoleRequired: "La demande de rôle de courtier est requise",
    required: "Ce champ est requis",
  },
  offerType:{
    phone: "Téléphone",
    link: "Lien",
    authorisation: "Autorisation",
    label: "Type d'offre",
    submissionNumber: "Numéro de soumission",
    priceAnually: "Prix annuel",
    expirationDate: "Date d'expiration",
    description: "Description",
    sendOffer: "Envoyer l'offre",
  },
  logout: {
    loggingOut: 'Veuillez patienter...',
    loggingOutMessage: 'Nous vous déconnectons, vous serez redirigé automatiquement'
  },
  pagination: {
    previous: 'Précédents',
    next: 'Suivants',
    showing: 'Résultats ',
    to: ' à ',
    of: ' sur ',
    results: ''
  },
  neatcover:{
    zeroToOneThousand: '0$ - 1000$',
    oneThousandToTwoThousands: '1000$ - 2000$',
    twoToThreeThousands: '2000$ - 3000$',
    threeThousandsPlus: '3000$ et plus',
    contactEmail: 'Courriel de contact',
    pendingAssuranceRoleApproval: 'Votre demande afin  d\'être un courtier partenaire est en cours de validation',
    pendingAssuranceRoleApprovalDescription: 'Nous vous contacterons dans les plus brefs délais pour finaliser votre inscription. Vous pouvez ajouter votre carte de crédit, qui sera nécessaire lors du choix de votre forfait, par votre profil.',
    openProfile: 'OUVRIR PROFIL'
  },
  month:{
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
  },
  search: {
    search: 'Rechercher',
    placeholder: 'Rechercher'
  }
};
export default fr;

