import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CarDetails } from "../../../components/AssuranceRequest/Row";
import Button from "../../../components/ButtonGroup/Button";
import List, { useList } from "../../../components/List/List";
import { discardUserAssurance, getNewInsuranceOffer } from "../../../services/insurer";
import { setUserAssurance } from "../../../store/actions/Insurance";
import Modal from "../../../components/Modal/Modal";
import Select from "../../../components/Form/Select";
import { months } from "../../../utils/months";
import InsuranceFiltersModal, { applyFilters, useInsuranceFilters } from "../../../components/InsuranceFiltersModal/InsuranceFiltersModal";
import PendingAssuranceRoleApproval, { usePendingAssuranceRoleApproval } from "../../../components/PendingAssuranceRoleApproval/PendingAssuranceRoleApproval";

const NewInsurance = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [page, setPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const { t } = useTranslation(['insurer']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [filteredTableData, setFilteredTableData] = useState(undefined);
  const insuranceFilters = useInsuranceFilters();
  const { pendingAssuranceRoleApproval } = usePendingAssuranceRoleApproval();
  const selectOffer = useCallback((insuranceOffer) => {
    dispatch(setUserAssurance(insuranceOffer));
    navigate(`/Insurer/Offer/${insuranceOffer.id}`);
  }, [navigate]);

  const loadData = useCallback(() => {
    getNewInsuranceOffer().subscribe({
      next: (newInsuranceOffers) =>
        {
          setTableData(newInsuranceOffers.assurances);
        }
    });
  }, []);

   useEffect(() => {
    setFilteredTableData(applyFilters(tableData, insuranceFilters));
  }, [tableData, insuranceFilters.filters.filters]);

  const { listData, setListData } = useList(filteredTableData || tableData, [
    {
      id: 'car',
      label: t('insurer:car'),
      render: (item) => {
        return <CarDetails key={item.id} userAssurance={item} />
      }
    },
    {
      id: 'actions',
      label: '',
      render: (item) => {
        return <Button className="bg-red-500 text-white" 
        onClick={(e) => {
          e.stopPropagation();
          discardUserAssurance(item.id).subscribe({
            next: (res) => {
              loadData();
            }
          });
        }}>
          {t('insurer:discard')}
        </Button>
      }
    }
  ], 1, 10);


  useEffect(() => {
    loadData();
  }, [dispatch, navigate, page]);

  return (
    <div className="flex-1 flex-row gap-x-8">

    
      
      {!pendingAssuranceRoleApproval &&  <div className="w-full justify-end flex flex-1 flex-row">
        <Button onClick={() => {insuranceFilters.setIsModalOpen(true)}}>{t('insurer:filters')} ({insuranceFilters.count()} {t('insurer:filtersApplied')})</Button>
      </div>}
      
      {!pendingAssuranceRoleApproval && <List listData={listData} setListData={setListData}  onClick={(item) => { selectOffer(item) }} />}
      {pendingAssuranceRoleApproval && <PendingAssuranceRoleApproval pendingAssuranceRoleApproval={pendingAssuranceRoleApproval} />}


      <InsuranceFiltersModal insuranceFilters={insuranceFilters} />
    </div>
  )
};

export default NewInsurance;
