
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next';
import { findPriceRange } from '../../utils/priceRange';
export default function Row({ userAssurance, selectOffer }) {
    return (
        <li key={userAssurance.id}>
            <div onClick={() => { selectOffer(userAssurance) }} className="block hover:bg-gray-50 cursor-pointer">
                <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                        <CarDetails userAssurance={userAssurance} />
                    </div>
                    <div>
                        <ChevronRightIcon className="h-5 w-5 text-neatcarGray" aria-hidden="true" />
                    </div>
                </div>
            </div>
        </li>
    )
}

export function CarDetails({ userAssurance }) {
    const { t } = useTranslation(['neatcover']);
    const priceThreshold = findPriceRange(userAssurance?.informations.assuranceAmount_yearly, t);
    return (
        <div key={`insuranceOffer-${userAssurance.id}`} className="min-w-0 flex-1 md:grid md:grid-cols-2 md:gap-4">
        <div className="flex flex-row items-center">
            <img src={userAssurance.userCar.pictureUrl} alt="carImage" className="w-16 h-16 object-contain mr-8" />
            <div>
                <p
                    className="text-lg font-bold text-neatcar truncate">{`${userAssurance.userCar.car.make} ${userAssurance.userCar.car.model} ${userAssurance.userCar.car.year}`}</p>
                <p className="text-sm font-regular text-neatcar truncate">{`${priceThreshold?.label}`}</p>
            </div>
        </div>
    </div>
    );
}