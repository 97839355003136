import AxiosInstance from "./api";
import { map, Observable } from "rxjs";
import { TransactionPage } from "../models/Page";
import i18n from '../i18n/index';

export function getNewInsuranceOffer (): Observable<string> {
 
  return AxiosInstance.get(`api/v2/support/assurance/unresponded`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getInsurerOfferPending (): Observable<string> {
 
  return AxiosInstance.get(`/api/v2/support/assurance/offer/unresponded`)
    .pipe(
      map(res => res.data.data)
    );
}


export function getBrands (): Observable<string> {
 
  return AxiosInstance.get(`api/v1/brand`)
    .pipe(
      map(res => res.data.data)
    );
}


export function createInsurerOffer ({brandId, priceAnually, expirationDate, userAssuranceId, description, offerType, phone, link, submissionNumber}): Observable<string> {
 
  const data = JSON.stringify({ brandId, priceAnually, expirationDate, userAssuranceId, description, offerType, phone, link, submissionNumber });
  return AxiosInstance.post(`api/v1/support/users/insurer`, data )
    .pipe(
      map(res => res.data.data)
    );
}

export function setAssuranceAsResponded (userAssuranceId): Observable<string> {
 
  return AxiosInstance.post(`api/v2/support/assurance/unresponded/${userAssuranceId}`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getAcceptedInsuranceOffer (): Observable<string> {
 
  return AxiosInstance.get(`api/v2/support/assurance/offer/unresponded`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getUnacceptedInsuranceOffer (): Observable<string> {
 
  return AxiosInstance.get(`api/v2/support/assurance/offer/unaccepted`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getAssuranceById (userAssuranceId): Observable<string> {
 
  return AxiosInstance.get(`api/v2/support/assurance/${userAssuranceId}`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getInsurerOfferByUserAssuranceId (userAssuranceId): Observable<string> {
 
  return AxiosInstance.get(`api/v2/support/offer?userAssuranceId=${userAssuranceId}`)
    .pipe(
      map(res => res.data.data)
    );
}

export function discardUserAssurance (userAssuranceId): Observable<string> {
 
  return AxiosInstance.delete(`api/v2/support/assurance/${userAssuranceId}`)
    .pipe(
      map(res => res.data.data)
    );
}

export function getDiscardedUserAssurance (): Observable<string> {
 
  return AxiosInstance.get(`api/v2/support/assurance/discarded`)
    .pipe(
      map(res => res.data.data)
    );
}

export function deleteInsurerOfferByUserAssuranceId (userAssuranceId): Observable<string> {
 
  return AxiosInstance.delete(`api/v2/support/offer`, {data: {userAssuranceId}})
    .pipe(
      map(res => res.data.data)
    );
}

export function getCarMakes (): Observable<string> {
 
  return AxiosInstance.get(`api/car/search`)
    .pipe(
      map(res => res.data.data)
    );
}