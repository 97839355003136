import { useTranslation } from "react-i18next";
import Button from "../ButtonGroup/Button"
import Select from "../Form/Select"
import Modal from "../Modal/Modal"
import { useEffect, useState } from "react";
import { months } from "../../utils/months";
import { priceRange } from "../../utils/priceRange";
import { getCarMakes } from "../../services/insurer";
import LoadingIndicator from "../LoadingIndicator";
import GearLoadingIndicator from "../GearLoadingIndicator";
import dayjs from "dayjs";

const defaultFilters = {
    temporaryFilters: {
        month: null,
        brand: null,
        offerType: null,
        submissionNumber: null,
        priceAnually: null,
        expirationDate: null,
    },
    filters: {
        month: null,
        brand: null,
        offerType: null,
        submissionNumber: null,
        priceAnually: null,
        expirationDate: null,
    },
    isModalOpen: false,
}

export const applyFilters = (tableData, insuranceFilters) => {

    if (insuranceFilters.filters.filters) {
        let filteredData = [...tableData];
        if (insuranceFilters.filters.filters.month?.id && insuranceFilters.filters.filters.month?.id !== 'all') {
            filteredData = filteredData.filter(item => item.informations.currentInsurerMonthExpiry === insuranceFilters.filters.filters.month.id);
        }

        if (insuranceFilters.filters.filters?.motorOption?.id && insuranceFilters.filters.filters?.motorOption?.id !== 'all') {
            filteredData = filteredData.filter(item => item.userCar.car.Engine.toLowerCase().includes(insuranceFilters.filters.filters.motorOption.id.toLowerCase()));
        }

        if (insuranceFilters.filters.filters?.carMake?.id && insuranceFilters.filters.filters?.carMake?.id !== 'all') {
            filteredData = filteredData.filter(item => item.userCar.car.make.toLowerCase() === insuranceFilters.filters.filters.carMake.name.toLowerCase());
        }

        if (insuranceFilters.filters.filters?.licenseType?.id && insuranceFilters.filters.filters?.licenseType?.id !== 'all') {
            filteredData = filteredData.filter(item => item.informations.carLicense.toLowerCase() === insuranceFilters.filters.filters.licenseType.id.toLowerCase());
        }

        if (insuranceFilters.filters.filters?.creditScore?.id && insuranceFilters.filters.filters?.creditScore?.id !== 'all') {
            filteredData = filteredData.filter(item => item.informations.creditScore.toLowerCase() === insuranceFilters.filters.filters.creditScore.id.toLowerCase());
        }

        if (insuranceFilters.filters.filters?.tickets?.id && insuranceFilters.filters.filters?.tickets?.id !== 'all') {
            filteredData = filteredData.filter(item => item.informations.last5YearsTickets0.toLowerCase().startsWith(insuranceFilters.filters.filters.tickets.id.toLowerCase()));
        }

        if (insuranceFilters.filters.filters?.accidents?.id && insuranceFilters.filters.filters?.accidents?.id !== 'all') {
            filteredData = filteredData.filter(item => item.informations.last6YearsAccidents0.toLowerCase().startsWith(insuranceFilters.filters.filters.accidents.id.toLowerCase()));
        }

        if (insuranceFilters.filters.filters?.ageFilter?.id && insuranceFilters.filters.filters?.ageFilter?.id !== 'all' && insuranceFilters.filters.filters?.age) {
            
            filteredData = filteredData.filter(item => {
                const filterAge = parseInt(insuranceFilters.filters.filters.age);
                const userAge = dayjs().diff(item.informations.birthday, 'years');

                if (insuranceFilters.filters.filters.ageFilter.id === 'equals') {
                    return userAge === filterAge;
                } else if (insuranceFilters.filters.filters.ageFilter.id === 'lessThan') {
                    return userAge < filterAge;
                } else if (insuranceFilters.filters.filters.ageFilter.id === 'moreThan') {
                    return userAge > filterAge;
                }
            });
        }

        if (insuranceFilters.filters.filters?.carYearFilter?.id && insuranceFilters.filters.filters?.carYearFilter?.id !== 'all' && insuranceFilters.filters.filters?.carYear) {
            filteredData = filteredData.filter(item => {
                const filterCarYear = parseInt(insuranceFilters.filters.filters.carYear);
                const userCarYear = parseInt(item.userCar.car.year);

                if (insuranceFilters.filters.filters.carYearFilter.id === 'equals') {
                    return userCarYear === filterCarYear;
                } else if (insuranceFilters.filters.filters.carYearFilter.id === 'lessThan') {
                    return userCarYear < filterCarYear;
                } else if (insuranceFilters.filters.filters.carYearFilter.id === 'moreThan') {
                    return userCarYear > filterCarYear;
                }
            });        }

        if ((insuranceFilters.filters.filters?.priceRange?.min ?? -1) >= 0 && insuranceFilters.filters.filters?.priceRange?.id !== 'all') {
            filteredData = filteredData.filter(item => item.informations.assuranceAmount_yearly >= insuranceFilters.filters.filters.priceRange.min && item.informations.assuranceAmount_yearly <= insuranceFilters.filters.filters.priceRange.max);
        }

        return filteredData;
    }
    return undefined;
}

export const useInsuranceFilters = () => {
    const [filters, setFilters] = useState(defaultFilters);
    const [carMakes, setCarMakes] = useState(undefined);
    const { t } = useTranslation('insurer', 'neatcover');

    const setIsModalOpen = (value) => {
        setFilters({ ...filters, isModalOpen: value });
    }

    useEffect(() => {
        if (!carMakes) {
            getCarMakes().subscribe({
                next: (carMakes) => {
                    const carMakesMapped = [
                        { id: 'all', name: t('insurer:all') },
                        ...carMakes.makes.map((item, index) => ({ id: index, name: item }))
                    ];
                    setCarMakes(carMakesMapped);
                }
            });
        }

    }, [carMakes]);

    useEffect(() => {
        const insuranceFilters = localStorage.getItem('insurance_filters');
        if (insuranceFilters) {
            const insuranceFiltersParsed = JSON.parse(insuranceFilters);
            setFilters({ ...filters, temporaryFilters: { ...insuranceFiltersParsed }, filters: { ...insuranceFiltersParsed } });
        }
    }, []);

    const count = () => {
        return Object.keys(filters.filters).filter(key => filters.filters[key] && filters.filters[key].id).length;
    }

    return { filters, setFilters, setIsModalOpen, carMakes, count }
}

export default function InsuranceFiltersModal({ insuranceFilters }) {
    const { filters, setFilters, setIsModalOpen, carMakes } = insuranceFilters;
    const { t } = useTranslation('insurer', 'neatcover');

    const handleApply = () => {
        setFilters({ ...filters, filters: { ...filters.temporaryFilters }, isModalOpen: false });
        localStorage.setItem('insurance_filters', JSON.stringify(filters.temporaryFilters));
    }

    const handleReset = () => {
        setFilters({ ...filters, ...defaultFilters });
        localStorage.removeItem('insurance_filters');
    }

    const handleCancelChange = () => {
        setFilters({ ...filters, temporaryFilters: { ...filters.filters }, isModalOpen: false });
    }

    const monthsWithAll = [
        { id: 'all', name: t('insurer:all') },
        ...months(t)
    ]

    const motorOptions = [
        { id: 'all', name: t('insurer:all') },
        { id: 'BATTERY', name: t('insurer:electric') },
        { id: 'GAS', name: t('insurer:gas') },
    ]

    const priceRangeOptions = [
        { id: 'all', name: t('insurer:all') },
    ].concat(priceRange(t).map(item => ({ ...item, id: item.min, name: item.label })));

    const licenseTypeOptions = [
        { id: 'all', name: t('insurer:all') },
        { id: 'COMMERCIAL', name: t('insurer:commercial') },
        { id: 'PERSONAL', name: t('insurer:personal') },
    ]

    const creditScoreOptions = [
        { id: 'all', name: t('insurer:all') },
        { id: 'unknown', name: t('insurer:creditUnknown')},
        { id: 'poor', name: t('insurer:creditPoor')},
        { id: 'fair', name: t('insurer:creditFair')},
        { id: 'good', name: t('insurer:creditGood')},
        { id: 'veryGood', name: t('insurer:creditVeryGood')},
        { id: 'excellent', name: t('insurer:creditExcellent') }
    ]

    const ticketsOption = [
        { id: 'all', name: t('insurer:all') },
        { id: 'yes', name: t('insurer:withTicket') },
        { id: 'no', name: t('insurer:withoutTicket') },
    ]

    const accidentsOption = [
        { id: 'all', name: t('insurer:all') },
        { id: 'yes', name: t('insurer:withAccident') },
        { id: 'no', name: t('insurer:withoutAccident') },
    ]

    const ageOptions = [
        { id: 'all', name: t('insurer:all') },
        { id: 'equals', name: t('insurer:equals') },
        { id: 'lessThan', name: t('insurer:lessThan') },
        { id: 'moreThan', name: t('insurer:moreThan') },
    ];
    const isAgeOptionSelected = filters.temporaryFilters.ageFilter?.id && filters.temporaryFilters.ageFilter?.id !== 'all';

    const carYearOptions = ageOptions;
    const isCarYearOptionSelected = filters.temporaryFilters.carYearFilter?.id && filters.temporaryFilters.carYearFilter?.id !== 'all';

    return (
        <Modal title={t('insurer:filters')} isOpen={filters.isModalOpen} onClose={handleCancelChange}>
            {carMakes && <div>
                <div className="flex flex-row justify-between space-x-4 w-full">
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.month} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, month: value } })} label={t('insurer:renewalMonth')} options={monthsWithAll} />
                    </div>
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.motorOption} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, motorOption: value } })} label={t('insurer:motorOption')} options={motorOptions} />
                    </div>
                </div>

                <div className="mt-4 flex flex-row justify-between space-x-4 w-full">
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.priceRange} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, priceRange: value } })} label={t('insurer:priceRange')} options={priceRangeOptions} />
                    </div>
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.carMake} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, carMake: value } })} label={t('insurer:carMake')} options={carMakes} />
                    </div>
                </div>

                <div className="mt-4 flex flex-row justify-between space-x-4 w-full">
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.licenseType} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, licenseType: value } })} label={t('insurer:licenseType')} options={licenseTypeOptions} />
                    </div>
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.creditScore} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, creditScore: value } })} label={t('insurer:creditScore')} options={creditScoreOptions} />
                    </div>
                </div>

                <div className="mt-4 flex flex-row justify-between space-x-4 w-full">
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.ageFilter} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, ageFilter: value } })} label={t('insurer:age')} options={ageOptions} />
                    </div>
                    <div className="flex flex-col flex-1 w-full items-center justify-end">
                        <input
                            disabled={!isAgeOptionSelected}
                            onChange={(e) => setFilters({...filters, temporaryFilters: { ...filters.temporaryFilters, age: e.target.value }})}
                            type="number"
                            name="age"
                            id="age"
                            value={filters.temporaryFilters.age || ""}
                            className={`${isAgeOptionSelected ? 'opacity-100' : 'opacity-50'} shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md h-12`}
                        />
                    </div>
                </div>

                <div className="mt-4 flex flex-row justify-between space-x-4 w-full">
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.carYearFilter} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, carYearFilter: value } })} label={t('insurer:carYear')} options={carYearOptions} />
                    </div>
                    <div className="flex flex-col flex-1 w-full items-center justify-end">
                        <input
                            disabled={!isCarYearOptionSelected}
                            onChange={(e) => setFilters({...filters, temporaryFilters: { ...filters.temporaryFilters, carYear: e.target.value }})}
                            type="number"
                            name="carYear"
                            id="carYear"
                            value={filters.temporaryFilters.carYear || ""}
                            className={`${isCarYearOptionSelected ? 'opacity-100' : 'opacity-50'} shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md h-12`}
                        />
                    </div>
                </div>

                <div className="mt-4 flex flex-row justify-between space-x-4 w-full">
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.tickets} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, tickets: value } })} label={t('insurer:tickets')} options={ticketsOption} />
                    </div>
                    <div className="flex flex-col flex-1 w-full">
                        <Select preselectedOption={filters.temporaryFilters.accidents} preselect={true} onSelect={(value) => setFilters({ ...filters, temporaryFilters: { ...filters.temporaryFilters, accidents: value } })} label={t('insurer:accidents')} options={accidentsOption} />
                    </div>
                </div>

                <div className="flex flex-row mt-4 justify-end">
                    <div className="flex flex-row flex-1">
                        <Button className="bg-red-500 text-white" onClick={handleReset}>{t('insurer:reset')}</Button>
                    </div>
                    <Button className="bg-transparent text-gray-800 mr-4" onClick={handleCancelChange}>{t('insurer:cancel')}</Button>
                    <Button className="bg-neatcar text-white" onClick={handleApply}>{t('insurer:apply')}</Button>
                </div>
            </div>}
            {!carMakes && <div className="flex flex-row justify-center items-center h-16">
                <div className="w-16 h-16">
                    <GearLoadingIndicator />
                </div>
            </div>}
        </Modal>
    )
}