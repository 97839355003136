const en = {
  login: {
    title: 'Business account',
    subtitle: 'No business account yet?',
    salesLinkLabel: 'Contact us.',
    salesLink: 'https://neatcar.com/en/contact.php',
    email: 'Email',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password?',
    signIn: 'Sign in',
    wrongCredentials: 'Wrong credentials',
    required: 'This field is required',
    wrongEmail: 'This email is not valid',
  },
  notFound: {
    error: '404 Error',
    lost: 'Uh oh! I think you’re lost.',
    subtitle: 'It looks like the page you’re looking for doesn\'t exist.',
    goBackHome: 'Go back home',
  },
  menu: {
    fleat: 'Fleat & Team',
    team: 'Team',
    billing: 'Billing',
    otherLanguage: 'Français',
    otherLanguageKey: 'fr',
    logout: 'Sign out',
    marketplace: 'Marketplace',
    marketplaceGiftTitle: 'Gift and experience',
    marketplaceGiftDesc: 'Offer Neatcar Premium washes as gifts to your clients with a personalized experience.',
    marketplaceDealershipTitle: 'Dealership',
    marketplaceDealershipDesc: 'Complete module to personalize a unique experience.',
    marketplaceStationTitle: 'Car wash station',
    marketplaceStationDesc: 'Add you car wash station in Neatcar and easily target thousands of car owner.',
    marketplaceAdCampaignTitle: 'Ad campaigns',
    marketplaceAdCampaignDesc: 'Are car owner your clients? Target them at the right time.',
    marketplaceSalesTitle: 'Want to talk with sales?',
    marketplaceSalesDesc: 'Reach us at: sales@neatcar.com',
    bidPendingUserApproval: 'Bid pending user approval',
    bidApprovedByUser: 'Bid approved by user',
    discarded: 'Discarded demands',
    new: 'New',
    profile: 'Profile',
    Insurer: 'Broker'
  },
  teams: {
    addMember: 'Add a member',
    email: 'Email',
    or: 'Or',
    userId: 'User ID',
    userIdHelp: 'The user ID can be found on the profile page in the mobile app.',
    addToTeam: 'Add to team',
    cancel: 'Cancel',
    empty: 'You need to enter an email or a user ID',
    errors: {
      11: 'Make sure the email or user ID is valid',
      25: 'This user can not be added because he is already in your team.',
      26: 'You have reached the maximum amount of employee for your plan. Contact us at sales@neatcar.com if you want to upgrade your plan.'
    },
    addSuccessTitle: '{{firstName}} has been successfully added',
    addSuccessMessage: '{{firstName}} can now buy car wash code according to his permissions.'
  },
  member: {
    delete: 'Remove',
    deleteTitle: 'Remove account',
    deleteText: 'Are you sure you want to remove this account? All of the data will be permanently removed from our servers forever. This action cannot be undone.',
    cancel: 'Cancel',
    maxCarwashPerMonth: 'Monthly wash limit',
    startHour: 'Min hour',
    endHour: 'Max hour',
    days: 'Days',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    update: 'Update',
    permissions: 'Permissions'
  },
  transaction: {
    transactions: 'Transactions',
    // eslint-disable-next-line no-template-curly-in-string
    price: '${{price}}',
    paidByCard: 'Paid by card **** {{digits}}',
    download: 'Download',
    export: 'Export CSV',
    noTransactions: 'No transactions'
  },
  billing: {
    paymentMethod: 'Payment method',
    update: 'Update',
    cardNumber: 'Card number',
    name: 'Name on card',
    expirationDate: 'Expiration date (MM/YY)',
    cvv: 'CVV',
    currentCard: 'Current credit card,',
    updateCard: 'Update the credit card',
    required: 'This field is required',
    invalid: 'Invalid field',
    modifiedCreditCardSuccessTitle: 'Credit card updated',
    modifiedCreditCardSuccessMessage: 'Your credit card has been updated successfully.',
    modifiedCreditCardErrorTitle: 'Credit card error',
    modifiedCreditCardErrorMessage: 'Your credit card update failed to be processed.'
  },
  insurer: {
    newInsurance: 'New insurance',
    filters: 'Filters',
    renewalMonth: 'Renewal month',
    reset: 'Reset',
    cancel: 'Cancel',
    apply: 'Apply',
    motorOption: 'Motor option',
    all: 'All',
    electric: 'Electric',
    gas: 'Gas',
    priceRange: 'Price range',
    carMake: 'Car make',
    commercial: 'Commercial',
    personal: 'Personal',
    licenseType: 'License type',
    creditScore: 'Credit score',
    creditUnknown: 'Unknown',
    creditPoor: 'Poor (300-579)',
    creditFair: 'Fair (580-669)',
    creditGood: 'Good (670-739)',
    creditVeryGood: 'Very good (740-799)',
    creditExcellent: 'Excellent (800-900)',
    tickets: 'Tickets',
    withTicket: 'With ticket',
    withoutTicket: 'Without ticket',
    accidents: 'Accidents',
    withAccident: 'With accident',
    withoutAccident: 'Without accident',
    age: 'Driver age',
    equals: 'Equals',
    lessThan: 'Less than',
    moreThan: 'More than',
    carYear: 'Car year',
    filtersApplied: 'applied',
    discard: 'Discard',
    car: 'Car',
    offer: 'Offer',
    user: 'User',
    amount: 'Amount',
    date: 'Date',
    status: 'Status',
    offerSent: 'Offer sent',
    insuranceOfferDetail: 'Insurance offer detail',
    accepted: 'Accepted',
    pending: 'Pending',
    deleteOffer: 'Delete offer',
    sendOffer: 'Send offer',
    driverInformation: 'Driver information',
    driver: 'Driver',
    fullName: 'Full name',
    address: 'Address',
    addressSince: 'Address since',
    licenseNumber: 'Driver license number',
    yearGotLicense: 'Age got license',
    birthday: 'Birthday',
    carLicense: 'Driver license type',
    isWithCurrentInsurerSince: 'Is with current insurer since',
    currentInsurer: 'Current insurer',
    insuranceRenewalMonth: 'Insurance renewal month',
    isInsuredSince: 'Is insured since',
    isOpenToHomeInsurance: 'Is open to home insurance',
    credit: 'Credit score',
    gender: 'Gender',
    weekOutOfState: 'Week out of state',
    verificationProfile: 'Verification profile',
    discount: 'Discount',
    carInformation: 'Car information',
    vin: 'VIN',
    currentPriceAnnually: 'Current price annually',
    damageToOther: 'Damage to other',
    riskToOther: 'Risk to other',
    collision: 'Collision',
    paymentSchedule: 'Payment schedule',
    avivaInsurer: "Aviva",
    belairDirectInsurer: "Belairdirect",
    benevaInsurer: "Beneva",
    caaQuebecInsurer: "CAA",
    cibcInsuranceInsurer: "CIBC",
    coOperatorsInsurer: "Co-operators",
    desjardinsInsurer: "Desjardins",
    economicalInsuranceInsurer: "Economical",
    iaFinancialGroupInsurer: "iA Groupe Financier",
    intactInsuranceInsurer: "Intact",
    laPersonnelleInsurer: "La Personnelle",
    nationalBankInsuranceInsurer: "Banque Nationale",
    promutuelAssuranceInsurer: "Promutuel",
    rbcInsuranceInsurer: "RBC",
    scotiaLifeFinancialInsurer: "Scotia Assurance",
    sonnetInsurer: "Sonnet",
    tdInsuranceInsurer: "TD Assurance",
    wawanesaInsurer: "Wawanesa",
    otherInsurer: "Other",
    noAssurance: "No Insurance",
    lessThan1Year: "Less than 1 year",
    "1Year": "1 year",
    "2Years": "2 years",
    "3Years": "3 years",
    "4Years": "4 years",
    "5YearsOrMore": "5 years or more",
    yes: "Yes",
    no: "No",
    male: "Male",
    female: "Female",
    other: "Other",
    never: "Never",
    lessThan3Weeks: "Less than 3 weeks",
    "4to7Weeks": "4 to 7 weeks",
    "8to15Weeks": "8 to 15 weeks",
    "16to26Weeks": "16 to 26 weeks",
    "moreThan26Weeks": "More than 26 weeks",
    none: "None",
    student: "Student",
    retired: "Retired",
    maried: "Married",
    fullTimeWork: "Full-Time Worker",
    universityDegree: "University Degree",
    hitAndRunExemption: "Hit & run exemption",
    newReplacement: "New replacement",
    accidentForgiveness: "Accident forgiveness",
    franchiseExemptionHitAndRun: "Franchise exemption for hit & run",
    purchaseAmount: "Purchase amount",
    antitheft: "Antitheft",
    boughtState: "Bought state",
    franchise: "Franchise",
    kilometerDaily: "Kilometer daily",
    interest: "Interest",
    boughtType: "Bought type",
    insurerCancelationNumberInLast5Years: "Insurer cancelation number in last 5 years",
    financeDuration: "Finance duration",
    boughtDate: "Bought date",
    civilResponsability: "Civil responsability",
    kilometerAnnually: "Kilometer annually",
    usage: "Usage",
    newCar: "New",
    usedCar: "Used",
    demoCar: "Demo",
    paid: "Fully paid",
    financed: "Financed",
    location: "Leased",
    "1": "1",
    "2OrMore": "2 or More",
    work: "Work",
    school: "School",
    drivingPleasure: "Pleasure",
    red: "Red",
    green: "Green",
    blue: "Blue",
    white: "White",
    black: "Black",
    gray: "Gray",
    orange: "Orange",
    yellow: "Yellow",
    carDetails: "Car Details",
    homeDetails: "Home Details",
    userSaidTheyHaveTicketsButDidNotProvideAnyInformation: "User said they have tickets but did not provide any information",
    userSaidTheyHaveAccidentsButDidNotProvideAnyInformation: "User said they have accidents but did not provide any information",
    explanation: "Explanation",
    date: "Date",
    accidents: "Accidents",
    sherlock: "Sherlock",
    tag: "Tag",
    alarm: "Alarm system",
    noAntiTheft: "No system",
    "2-weeks": "2 weeks",
    Monthly: "Monthly",
    Annually: "Annually",
    dangerousMatterUsed: "Is used to transport dangerous materials.",
    contestUsed: "Is modified, rebuilt, or used for competition, exhibition, or racing purposes.",
    registeredOutOfState: "Is registered out of my state or managed far from my personal address.",
    UberOrTuro: "Is used for ridesharing (e.g., Uber) or rental (e.g., Turo)",
    claimWaiting: "Has claims waiting, in progress, or waiting to be fixed.",
    criminalRecord: "Has a criminal record or an ongoing legal proceeding against you.",
    medicalCondition: "Has medical conditions that affect their driving.",
    licenseRevoked: "Has had their driver's license suspended or revoked in the last 5 years.",
    bankruptcy: "Has filed for bankruptcy. (A bankruptcy discharged for 3 years or more is not considered.)",
    insurerRefused: "Has been refused an insurance policy or had their insurance policy canceled. A refusal to renew is considered.",
  },
  insurerCreation:{
    title: "Create your broker account",
    subtitle: "Create your broker account right now and we'll review your account within 48 hours.",
    email: "Email",
    password: "Password",
    rememberMe: "Remember me",
    signUp: "Sign up",
    firstName: "First name",
    lastName: "Last name",
    confirmPassword: "Confirm password",
    requestAssuranceRole: "Request assurance role",
    passwordDoesNotMatch: "The password and confirmation password do not match.",
    passwordRequired: "Password is required",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required",
    emailRequired: "Email is required",
    confirmPasswordRequired: "Confirm password is required",
    requestAssuranceRoleRequired: "Request assurance role is required",
    required: "This field is required",
  },
  offerType:{
    phone: "Phone",
    link: "Link",
    authorisation: "Authorisation",
    label: "Offer type",
    submissionNumber: "Submission number",
    priceAnually: "Price annually",
    expirationDate: "Expiration date",
    description: "Description",
    sendOffer: "Send offer",
  },
  logout: {
    loggingOut: 'Please wait...',
    loggingOutMessage: 'We are logging you out, you\'ll be automatically redirected'
  },
  pagination: {
    previous: 'Previous',
    next: 'Next',
    showing: 'Showing ',
    to: ' to ',
    of: ' of ',
    results: ' results'
  },
  neatcover:{
    zeroToOneThousand: '$0 - $1000',
    oneThousandToTwoThousands: '$1000 - $2000',
    twoToThreeThousands: '$2000 - $3000',
    threeThousandsPlus: '$3000 and more',
    contactEmail: 'Contact email',
    pendingAssuranceRoleApproval: 'Your request to become a broker partner is being validated',
    pendingAssuranceRoleApprovalDescription: 'We will contact you as soon as possible to finalize your registration. You can add your credit card, which will be necessary when choosing your plan, by your profile.',
    openProfile: 'OPEN PROFILE'
  },
  month:{
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  },
  search: {
    search: 'Search',
    placeholder: 'Search'
  },
  //TABLE
  network: {
    "list-headers" : {
      "brand.name": "Name",
      "address": "address",
      "codes": "codes",
      "alertFr": "alertFr",
      "note": "note",
      "active": "active"
      

    }
  },
  //dropdown
  dropdown : {

  }
};


export default en;
