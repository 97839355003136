import { useEffect, useState } from "react";
import Select from "../../../components/Form/Select"
import Modal from "../../../components/Modal/Modal"
import Button from "../../../components/ButtonGroup/Button";
import { getBrands, createInsurerOffer, setAssuranceAsResponded } from "../../../services/insurer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function NewOfferModal({isModalOpen, setIsModalOpen, userAssuranceId}) {
    const [form, setForm] = useState({});
    const [brands, setBrands] = useState(undefined);
    const navigate = useNavigate();

    const { user, roles } = useSelector(store => store.session);
    const { t } = useTranslation(['insurer', 'offerType']);

    useEffect(() => {
        if (!brands) {
            getBrands().subscribe({
            next: (brands) => {
              setBrands(brands.brands);

              if (!roles.find(role => role.role === 'ROLE_ADMIN') && brands.brands.length > 0) {
                setForm({...form, brandId: brands.brands[0].id});
              }
            }
          });
        }
      }, [isModalOpen])

    const disabled = !form.brandId || !form.priceAnually || !form.expirationDate || !form.description || !userAssuranceId;

    const handleCreateInsurerOffer = () => {
        if (disabled || !brands || brands.length === 0) {
            return;
        }

        createInsurerOffer({brandId: form.brandId, priceAnually: parseInt(parseFloat(form.priceAnually) * 100),
             expirationDate: form.expirationDate, userAssuranceId: userAssuranceId, description: form.description,
             offerType: form.offerType, phone: form.phone, link: form.link, submissionNumber: form.submissionNumber}).subscribe({
            next: (response) => {
                setAssuranceAsResponded(userAssuranceId).subscribe({
                    next: (response) => {
                        navigate('/Insurer');
                        setIsModalOpen(false);
                    }
                });
            }
        });
    }

    const offerTypeOptions = [
        {id: 'PHONE', name: t('offerType:phone')},
        {id: 'LINK', name: t('offerType:link')},
        {id: 'AUTHORISATION', name: t('offerType:authorisation')},
    ]

    return (
        <Modal title="New offer" isOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}}>
            
            {roles.find(role => role.role === 'ROLE_ADMIN') && <Select onSelect={(value) => setForm({...form, brandId: value.id})} label="Select brand" options={brands} />}
            <div className="mt-4">
                <Select onSelect={(value) => setForm({...form, offerType: value.id})} label={t('offerType:label')} options={offerTypeOptions} />    
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">{t('offerType:submissionNumber')}</span>
                <input
                    onChange={(e) => setForm({...form, submissionNumber: e.target.value})}
                    type="text"
                    name="submissionNumber"
                    id="submissionNumber"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="#15020-300-203"
                />
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">{t('offerType:phone')}</span>
                <input
                    onChange={(e) => setForm({...form, phone: e.target.value})}
                    type="text"
                    name="phone"
                    id="phone"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="+1-800-123-4567"
                />
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">{t('offerType:link')}</span>
                <input
                    onChange={(e) => setForm({...form, link: e.target.value})}
                    type="text"
                    name="link"
                    id="link"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="https://www.neatcover.ca"
                />
            </div>
            
            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">{t('offerType:priceAnually')}</span>
                <input
                    onChange={(e) => setForm({...form, priceAnually: e.target.value})}
                    type="number"
                    name="priceAnually"
                    id="priceAnually"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="2499.99"
                />
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">{t('offerType:expirationDate')}</span>
                <input
                    onChange={(e) => setForm({...form, expirationDate: e.target.value})}
                    type="date"
                    name="expirationDate"
                    id="expirationDate"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="2025-01-01"
                />
            </div>

            <div className="flex flex-col mt-4">
                <span className="text-base font-medium text-gray-900">{t('offerType:description')}</span>
                <textarea
                    onChange={(e) => setForm({...form, description: e.target.value})}
                    type="text"
                    name="description"
                    id="description"
                    className="shadow-sm focus:ring-neatcar focus:border-neatcar block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Description"
                />
            </div>

            <div className="flex justify-end mt-4">
                <Button disabled={disabled} onClick={handleCreateInsurerOffer}>
                    {t('offerType:sendOffer')}
                </Button>
            </div>
      </Modal>
    )
}       