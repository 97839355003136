import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getCurrentCard, setCreditCardLoading } from "../../../store/actions/Business";
import { updateCard } from "../../../services/business";
import { pushNotification } from "../../../store/actions/Notifications";
import { ERROR, Notification, SUCCESS } from "../../../models/Notification";
import { getAcceptedInsuranceOffer, getNewInsuranceOffer, getUnacceptedInsuranceOffer } from "../../../services/insurer";
import { StationsTable } from "../../../table/station/StationsTable"
import { columns } from "../../../table/station/columns"
import InsurerOfferAccepted, { OfferDetails, UserDetails } from "../../../components/InsurerOfferAccepted/Row";
import List, { useList } from "../../../components/List/List";
import { CarDetails } from "../../../components/AssuranceRequest/Row";
import { setUserAssurance } from "../../../store/actions/Insurance";
import PendingAssuranceRoleApproval, { usePendingAssuranceRoleApproval } from "../../../components/PendingAssuranceRoleApproval/PendingAssuranceRoleApproval";

const BidPendingUserApproval = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [page, setPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const { t } = useTranslation(['insurer']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stores = useSelector(store => store.support);
  const [tableData, setTableData] = useState([]);
  const { pendingAssuranceRoleApproval } = usePendingAssuranceRoleApproval();


  const { listData, setListData } = useList(tableData, [
    {
      id: 'car',
      label: t('insurer:car'),
      render: (item) => {
        const userAssurance = item.userAssurance;
        return <CarDetails userAssurance={userAssurance} />
      }
    },
    {
      id: 'offer',
      label: t('insurer:offer'),
      render: (item) => {
        return <OfferDetails insuranceOffer={item} />
      }
    }
  ], 1, 10);

  const [data, setData] = useState([]);

  useEffect(() => {

    getUnacceptedInsuranceOffer().subscribe({
      next: (newInsuranceOffers) => {
        setTableData(newInsuranceOffers.offers);
      }
    });


  }, [dispatch, navigate, page]);


  const selectOffer = React.useCallback((insuranceOffer) => {
    dispatch(setUserAssurance(insuranceOffer.userAssurance));
    navigate(`/Insurer/Offer/${insuranceOffer.userAssuranceId}`);
  }, [navigate]);

  return (
    <div className="flex-1 flex-row gap-x-8">
      {!pendingAssuranceRoleApproval && <List onClick={(item) => { selectOffer(item) }} listData={listData} setListData={setListData} />}
      {pendingAssuranceRoleApproval && <PendingAssuranceRoleApproval pendingAssuranceRoleApproval={pendingAssuranceRoleApproval} />}
    </div>
  )
};

export default BidPendingUserApproval;
