
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { CarDetails } from '../AssuranceRequest/Row';
import dayjs from 'dayjs';
export default function Row({ insuranceOffer, selectOffer }) {
    const userAssurance = insuranceOffer.userAssurance;

    return (
        <li key={userAssurance.id}>
            <div onClick={() => { selectOffer(userAssurance) }} className="block hover:bg-gray-50 cursor-pointer">
                <div className="flex items-center px-4 py-4 sm:px-6">
                    <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
                            <CarDetails userAssurance={userAssurance} />
                            <UserDetails insuranceOffer={insuranceOffer} />
                            <OfferDetails insuranceOffer={insuranceOffer} />
                        </div>
                    </div>
                    <div>
                        <ChevronRightIcon className="h-5 w-5 text-neatcarGray" aria-hidden="true" />
                    </div>
                </div>
            </div>
        </li>
    )
}

export function UserDetails({ insuranceOffer }) {
    return (
        <div>
            <p className="text-lg font-bold text-neatcar truncate">{`${insuranceOffer.user.firstName} ${insuranceOffer.user.lastName}`}</p>
            <p className="text-sm font-regular text-neatcar truncate">{`${insuranceOffer.user.email} - ${insuranceOffer.user.cellphone}`}</p>
        </div>
    )
}

export function OfferDetails({ insuranceOffer }) {
    return (
        <div>
            <p className="text-lg font-bold text-neatcar truncate">{`${parseFloat(insuranceOffer.priceAnually / 100).toFixed(2)}$`}</p>
            <p className="text-sm font-regular text-neatcar truncate">{`${dayjs(insuranceOffer.createdAt).format('YYYY-MM-DD')}`}</p>
        </div>
    )
}