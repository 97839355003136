export const months = (t) => {return [
  {
    id: "january",
    name: t('month:january')
  },
  {
    id: "february",
    name: t('month:february')
  },
  {
    id: "march",
    name: t('month:march')
  },
  {
    id: "april",
    name: t('month:april')
  },
  {
    id: "may",
    name: t('month:may')
  },
  {
    id: "june",
    name: t('month:june')
  },
  {
    id: "july",
    name: t('month:july')
  },
  {
    id: "august",
    name: t('month:august')
  },
  {
    id: "september",
    name: t('month:september')
  },
  {
    id: "october",
    name: t('month:october')
  },
  {
    id: "november",
    name: t('month:november')
  },
  {
    id: "december",
    name: t('month:december')
  }
  ]}