import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { getCurrentCard, setCreditCardLoading } from "../../../store/actions/Business";
import { updateCard } from "../../../services/business";
import { pushNotification } from "../../../store/actions/Notifications";
import { ERROR, Notification, SUCCESS } from "../../../models/Notification";
import { deleteInsurerOfferByUserAssuranceId, getAssuranceById, getInsurerOfferByUserAssuranceId, getNewInsuranceOffer } from "../../../services/insurer";
import { StationsTable } from "../../../table/station/StationsTable"
import { ChevronRightIcon, MailIcon } from '@heroicons/react/solid'
import { setUserAssurance } from "../../../store/actions/Insurance";
import Modal from "../../../components/Modal/Modal";
import NewOfferModal from "./NewOfferModal";
import Button from "../../../components/ButtonGroup/Button";
import dayjs from "dayjs";
import { findPriceRange, priceRange } from "../../../utils/priceRange";



const InsuranceOfferDetail = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [didFetchDetail, setDidFetchDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const { t } = useTranslation(['insurer', 'neatcover', 'month']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { offerId } = useParams();
  const [insurerOffer, setInsurerOffer] = useState(undefined);

  const insuranceStore = useSelector(store => store.insurance);
  const userAssurance = insuranceStore.userAssurance;

  useEffect(() => {
    if (!didFetchDetail) {
      setDidFetchDetail(true);
      const userAssuranceId = parseInt(offerId);
      getAssuranceById(userAssuranceId).subscribe({
        next: (userAssuranceDetail) => {
          dispatch(setUserAssurance(userAssuranceDetail.assurance));
        }
      });

      getInsurerOfferByUserAssuranceId(userAssuranceId).subscribe({
        next: (insurerOffer) => {
          setInsurerOffer(insurerOffer.offer);
        }
      });
    }
  }, [didFetchDetail])

  const deleteOffer = React.useCallback(() => {
    const userAssuranceId = parseInt(offerId);
    deleteInsurerOfferByUserAssuranceId(userAssuranceId).subscribe({
      next: (response) => {
        navigate('/Insurer');
      }
    });
  }, [offerId])

  const userHaveTicket = userAssurance?.informations.last5YearsTickets0 === "yes";

  const tickets = React.useMemo(() => {
    if (userAssurance) {
      var tmpTickets = [];
      for (let i = 0; i < 100; i++) {
        if (userAssurance?.informations[`explainTicket${i}`]) {
          tmpTickets.push({
            explain: userAssurance?.informations[`explainTicket${i}`],
            year: userAssurance?.informations[`ticketYear${i}`]
          })
        }
      }
      return tmpTickets;
    }
  }, [userAssurance]);

  const userHaveAccident = userAssurance?.informations.last6YearsAccidents0 === "yes";

  const accidents = React.useMemo(() => {
    if (userAssurance) {
      var tmpTickets = [];
      for (let i = 0; i < 100; i++) {
        if (userAssurance?.informations[`explainAccident${i}`]) {
          tmpTickets.push({
            explain: userAssurance?.informations[`explainAccident${i}`],
            claimAmount: userAssurance?.informations[`claimAmount${i}`],
            claimDate: dayjs(userAssurance?.informations[`claimDate${i}`]).format('YYYY-MM-DD')
          })
        }
      }
      return tmpTickets;
    }
  }, [userAssurance]);

  const userHaveInsuranceDetailCar= userAssurance?.informations.insuranceDetailCar && userAssurance?.informations.insuranceDetailCar.length > 0;
  const userInsuranceDetailCar = userHaveInsuranceDetailCar && userAssurance?.informations.insuranceDetailCar.split(',');

  const userHaveInsuranceDetailHome= userAssurance?.informations.insuranceDetailHome && userAssurance?.informations.insuranceDetailHome.length > 0;
  const userInsuranceDetailHome = userHaveInsuranceDetailHome && userAssurance?.informations.insuranceDetailHome.split(',');

  const priceThreshold = findPriceRange(userAssurance?.informations.assuranceAmount_yearly, t);

  const capitalize = s => s && String(s[0]).toUpperCase() + String(s).slice(1)

  const discountsKey = userAssurance?.informations.discount?.split(',') || [];
  const discountsLabel = discountsKey?.map(key => t(`insurer:${key}`)).join(', ');

  const userInsuranceDetailCarKey = userInsuranceDetailCar || [];
  const userInsuranceDetailCarLabel = userInsuranceDetailCarKey?.map(key => t(`insurer:${key}`));

  const userInsuranceDetailHomeKey = userInsuranceDetailHome || [];
  const userInsuranceDetailHomeLabel = userInsuranceDetailHomeKey?.map(key => t(`insurer:${key}`));


  return (
    <div className="min-h-full">

      <main className="pb-8">
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sr-only">{t('insurer:insuranceOfferDetail')}</h1>
          <div className="flex justify-end mb-8">
            {insurerOffer ? <div className="w-full border-b border-gray-200">
              <p className="text-2xl font-bold text-neatcar">{t('insurer:offerSent')}</p>
              <div className="flex flex-row items-center justify-between mt-4">
                <span className="text-lg font-bold text-gray-900 w-1/3">{t('insurer:amount')}</span>
                <span className="text-lg font-bold text-gray-900 w-1/3">{t('insurer:date')}</span>
                <span className="text-lg font-bold text-gray-900 flex-1">{t('insurer:status')}</span>
              </div>
              <div className="flex flex-row items-center justify-between pb-2">
                <span className="text-base font-medium text-neatcar w-1/3">{parseFloat(insurerOffer.priceAnually / 100).toFixed(2)}$</span>
                <span className="text-base font-medium text-neatcar w-1/3">{dayjs(insurerOffer.createdAt).format('YYYY-MM-DD HH:mm')}</span>
                <span className={`text-base font-medium flex-1 ${insurerOffer.isAccepted ? "text-green-600" : "text-yellow-600"}`}>{insurerOffer.isAccepted ? t('insurer:accepted') : t('insurer:pending')}</span>
                <Button className={'bg-red-600'} onClick={deleteOffer}>
                  {t('insurer:deleteOffer')}
                </Button>
              </div>
            </div> :
              <Button onClick={() => { setIsModalOpen(true) }}>
                {t('insurer:sendOffer')}
              </Button>}
          </div>
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <div className="grid grid-cols-1 gap-4">
              <section aria-labelledby="section-2-title">
                <h2 className="sr-only" id="section-2-title">{t('insurer:driverInformation')}</h2>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <div className="border-b border-gray-200 bg-white pb-3">
                      <h3 className="text-base font-semibold text-gray-900">{t('insurer:driver')}</h3>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:fullName')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.firstName} {userAssurance?.informations.lastName}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('neatcover:contactEmail')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{`neatcover+${userAssurance?.userId}@neatcar.com`}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:address')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.address}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:addressSince')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{dayjs(userAssurance?.informations.addressDate).format('YYYY-MM-DD')}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:licenseNumber')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.licenseNumber}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:yearGotLicense')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.yearGotLicense}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:birthday')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{dayjs(userAssurance?.informations.birthday).format('YYYY-MM-DD')}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:carLicense')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{userAssurance?.informations.carLicense}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:creditScore')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:credit${capitalize(userAssurance?.informations.creditScore)}`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:currentInsurer')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:${userAssurance?.informations.currentInsurer}Insurer`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:insuranceRenewalMonth')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`month:${userAssurance?.informations.currentInsurerMonthExpiry?.toLowerCase()}`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:isInsuredSince')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:${userAssurance?.informations.howLongActualAssurance}`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:isOpenToHomeInsurance')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:${userAssurance?.informations.insuranceHome}`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:gender')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:${userAssurance?.informations.genderCertificate || userAssurance?.informations.gender}`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:weekOutOfState')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:${userAssurance?.informations.weekOutOfState}`)}</dd>
                        </div>
                      </dl>
                    </div>
                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:verificationProfile')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`insurer:${userAssurance?.informations.verificationProfile}`)}</dd>
                        </div>
                      </dl>
                    </div>

                    <div className="border-b border-gray-100">
                      <dl className="divide-y divide-gray-100">
                        <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                          <dt className="text-sm/6 font-medium text-gray-900">{t('insurer:discount')}</dt>
                          <dd className="mt-1 text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">{discountsLabel}</dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="grid grid-cols-1 gap-4 lg:col-span-2">
              <section aria-labelledby="section-1-title">
                <h2 className="sr-only" id="section-1-title">{t('insurer:carInformation')}</h2>
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <div className="p-6">
                    <div className="border-b border-gray-200 bg-white pb-3 flex flex-row">
                      <div className="w-1/2 border-r px-8">
                        <h3 className="text-base font-semibold text-gray-900">{userAssurance?.userCar.car.make} {userAssurance?.userCar.car.model}</h3>
                        <p>{userAssurance?.userCar.car.year} - {t(`insurer:${userAssurance?.userCar.color}`)}</p>
                      </div>
                      <div className="w-1/2 bg-white pb-3 px-8">
                        <h3 className="text-base font-semibold text-gray-900">{t('insurer:vin')}</h3>
                        <p>{userAssurance?.informations.vin}</p>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:currentPriceAnnually')}</h3>
                          <p>{priceThreshold?.label}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:damageToOther')}</h3>
                          <p>{userAssurance?.informations.damageOther}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:riskToOther')}</h3>
                          <p>{userAssurance?.informations.riskOther}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:collision')}</h3>
                          <p>{userAssurance?.informations.colision ? t('insurer:yes') : t('insurer:no')}</p>
                        </div>

                      </div>
                      <div className="overflow-hidden p-6 flex-col space-y-4">

                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:paymentSchedule')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.assuranceAmount_segmentControllerOption.replace(' ', '-')}`)}</p>
                        </div>

                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:hitAndRunExemption')}</h3>
                          <p>{userAssurance?.informations.franchiseExemptionHitAndRun ? t('insurer:yes') : t('insurer:no')}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:newReplacement')}</h3>
                          <p>{userAssurance?.informations.newReplacement ? t('insurer:yes') : t('insurer:no')}</p>
                        </div>

                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:accidentForgiveness')}</h3>
                          <p>{userAssurance?.informations.accidentForgiveness ? t('insurer:yes') : t('insurer:no')}</p>
                        </div>
                      </div>
                      <div className="overflow-hidden">
                        <div className="p-6">
                          <img src={userAssurance?.userCar.pictureUrl} alt="carImage" className="w-48 h-48 object-contain" />
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-3 gap-4 -mt-8">
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:purchaseAmount')}</h3>
                          <p>{userAssurance?.informations.financeAmount}$</p>
                        </div>
                        <div className="inline-block  w-full">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:antitheft')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.antitheft}`)}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:boughtState')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.boughtState}Car`)}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:franchise')}</h3>
                          <p>{userAssurance?.informations.franchise}$</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:kilometerDaily')}</h3>
                          <p>{userAssurance?.informations.kilometerDaily}KM</p>
                        </div>
                      </div>
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:interest')}</h3>
                          <p>{userAssurance?.informations.interest}%</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:currentInsurer')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.currentInsurer}Insurer`)}</p>
                        </div>
                        <div className="inline-block">  
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:boughtType')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.boughtType}`)}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:insurerCancelationNumberInLast5Years')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.insurerCancelationNumberInLast5Years}`)}</p>
                        </div>
                      </div>
                      <div className="overflow-hidden flex-col p-6 space-y-4">
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:financeDuration')}</h3>
                          <p>{userAssurance?.informations.financeDuration}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:boughtDate')}</h3>
                          <p>{dayjs(userAssurance?.informations.boughtDate).format('YYYY-MM-DD')}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:civilResponsability')}</h3>
                          <p>{userAssurance?.informations.civilResponsability}</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:kilometerAnnually')}</h3>
                          <p>{userAssurance?.informations.kilometerAnnually}KM</p>
                        </div>
                        <div className="inline-block">
                          <h3 className="text-base font-semibold text-gray-900">{t('insurer:usage')}</h3>
                          <p>{t(`insurer:${userAssurance?.informations.usage}`)}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
                  <div className="p-6">
                    {userHaveInsuranceDetailCar && <ul className="w-full space-y-8">
                      <li className="flex flex-row pb-2">
                        <div className="w-1/2">
                          <p className="text-3xl font-bold text-neatcar">
                            {t('insurer:carDetails')}
                          </p>
                        </div>
                      </li>
                      {userInsuranceDetailCarLabel.map((carDetail, index) => (
                        <li key={`ticket-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                          <div className="w-full">
                            <p>
                              {carDetail}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </div>
                </div>
                <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
                  <div className="p-6">
                    {userHaveInsuranceDetailHome && <ul className="w-full space-y-8">
                      <li className="flex flex-row pb-2">
                        <div className="w-1/2">
                          <p className="text-3xl font-bold text-neatcar">
                            {t('insurer:homeDetails')}
                          </p>
                        </div>
                      </li>
                      {userInsuranceDetailHomeLabel.map((homeDetail, index) => (
                        <li key={`ticket-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                          <div className="w-full">
                            <p>
                              {homeDetail}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="flex w-full mt-16 flex-col">
            <div className="flex flex-col w-full">
              <p className="font-bold text-3xl ">{t('insurer:tickets')}</p>
              {userAssurance?.informations.last5YearsTickets0 === 'yesButNoInformation' && <p className="text-md font-bold text-red-400">{t('insurer:userSaidTheyHaveTicketsButDidNotProvideAnyInformation')}</p>}

            </div>
            {userHaveTicket && <ul className="w-full space-y-8 mt-8">
              <li className="flex flex-row border-b border-gray-200 pb-2">
                <div className="w-1/2">
                  <p className="text-lg font-bold text-neatcar">
                    {t('insurer:explanation')}
                  </p>
                </div>
                <div className="w-1/2">
                  <p className="text-lg font-bold text-neatcar">
                    {t('insurer:date')}
                  </p>
                </div>
              </li>
              {tickets.map((ticket, index) => (
                <li key={`ticket-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                  <div className="w-1/2">
                    <p>
                      {ticket.explain}
                    </p>
                  </div>
                  <div className="w-1/2">
                    <p>
                      {dayjs(ticket.year).format('YYYY-MM-DD')}
                    </p>
                  </div>
                </li>
              ))}
            </ul>}
          </div>
          <div className="flex w-full mt-16 flex-col">
            <div className="flex flex-col w-full">
              <p className="font-bold text-3xl ">{t('insurer:accident')}</p>
              {userAssurance?.informations.last6YearsAccidents0 === 'yesButNoInformation' && <p className="text-md font-bold text-red-400">{t('insurer:userSaidTheyHaveAccidentsButDidNotProvideAnyInformation')}</p>}
            </div>
            {userHaveAccident && <ul className="w-full space-y-8 mt-8">
              <li className="flex flex-row border-b border-gray-200 pb-2">
                <div className="w-1/3">
                  <p className="text-lg font-bold text-neatcar">
                    {t('insurer:explanation')}
                  </p>
                </div>
                <div className="w-1/3">
                  <p className="text-lg font-bold text-neatcar">
                    {t('insurer:amount')}
                  </p>
                </div>
                <div className="w-1/3">
                  <p className="text-lg font-bold text-neatcar">
                    {t('insurer:date')}
                  </p>
                </div>
              </li>
              {accidents.map((accident, index) => (
                <li key={`accident-${index}`} className="flex flex-row border-b border-gray-200 pb-2">
                  <div className="w-1/3">
                    <p>
                      {accident.explain}
                    </p>
                  </div>
                  <div className="w-1/3">
                    <p>
                      {accident.claimAmount}$
                    </p>
                  </div>
                  <div className="w-1/3">
                    <p>
                      {dayjs(accident.claimDate).format('YYYY-MM-DD')}
                    </p>
                  </div>
                </li>
              ))}
            </ul>}
          </div>
        </div>
        <NewOfferModal userAssuranceId={userAssurance?.id} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </main>
    </div>
  )
};

export default InsuranceOfferDetail;
