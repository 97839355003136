import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CarDetails } from "../../../components/AssuranceRequest/Row";
import Button from "../../../components/ButtonGroup/Button";
import List, { useList } from "../../../components/List/List";
import { discardUserAssurance, getDiscardedUserAssurance, getNewInsuranceOffer } from "../../../services/insurer";
import { setUserAssurance } from "../../../store/actions/Insurance";
import PendingAssuranceRoleApproval, { usePendingAssuranceRoleApproval } from "../../../components/PendingAssuranceRoleApproval/PendingAssuranceRoleApproval";

const DiscardedUserAssurance = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [page, setPage] = useState(1);
  const [csvLoading, setCsvLoading] = useState(false);
  const { t } = useTranslation(['insurer']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const { pendingAssuranceRoleApproval } = usePendingAssuranceRoleApproval();


  const selectOffer = useCallback((insuranceOffer) => {
    dispatch(setUserAssurance(insuranceOffer));
    navigate(`/Insurer/Offer/${insuranceOffer.id}`);
  }, [navigate]);

  const loadData = useCallback(() => {
    getDiscardedUserAssurance().subscribe({
      next: (newInsuranceOffers) =>
        {
          setTableData(newInsuranceOffers.assurances);
        }
    });
  }, []);

  const { listData, setListData } = useList(tableData, [
    {
      id: 'car',
      label: t('insurer:car'),
      render: (item) => {
        return <CarDetails key={item.id} userAssurance={item} />
      }
    }
  ], 1, 10);


  useEffect(() => {
    loadData();
  }, [dispatch, navigate, page]);

  return (
    <div className="flex-1 flex-row gap-x-8">
      {!pendingAssuranceRoleApproval && <List listData={listData} setListData={setListData}  onClick={(item) => { selectOffer(item) }} />}
      {pendingAssuranceRoleApproval && <PendingAssuranceRoleApproval pendingAssuranceRoleApproval={pendingAssuranceRoleApproval} />}
    </div>
  )
};

export default DiscardedUserAssurance;
