import { useState } from 'react'

const people = [
    { id: 1, name: 'Wade Cooper' },
    { id: 2, name: 'Arlene Mccoy' },
    { id: 3, name: 'Devon Webb' },
    { id: 4, name: 'Tom Cook' },
    { id: 5, name: 'Tanya Fox' },
    { id: 6, name: 'Hellen Schmidt' },
    { id: 7, name: 'Caroline Schultz' },
    { id: 8, name: 'Mason Heaney' },
    { id: 9, name: 'Claudie Smitham' },
    { id: 10, name: 'Emil Schaefer' },
]

export default function Select({label, options = [], onSelect, preselect = true, preselectedOption = undefined}) {
    const [selected, setSelected] = useState(preselectedOption ? preselectedOption : preselect ? options && options.length > 0 ? options[0] : undefined : undefined)
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (option) => {
        setSelected(option);
        onSelect(option);
        setIsOpen(false);
    }

    return (
        <div>
            <label id="listbox-label" className="block text-base font-medium text-gray-900">{label}</label>
            <div className="relative mt-2">
                <button onClick={() => {setIsOpen(!isOpen)}} type="button" className="grid w-full item-center justify-center h-12 cursor-default grid-cols-1 rounded-md bg-white py-1.5 pl-3 pr-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-sky-800 sm:text-sm/6" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                    <span className="col-start-1 row-start-1 truncate pr-6 self-center">{selected?.name}</span>
                    <svg className="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path fillRule="evenodd" d="M5.22 10.22a.75.75 0 0 1 1.06 0L8 11.94l1.72-1.72a.75.75 0 1 1 1.06 1.06l-2.25 2.25a.75.75 0 0 1-1.06 0l-2.25-2.25a.75.75 0 0 1 0-1.06ZM10.78 5.78a.75.75 0 0 1-1.06 0L8 4.06 6.28 5.78a.75.75 0 0 1-1.06-1.06l2.25-2.25a.75.75 0 0 1 1.06 0l2.25 2.25a.75.75 0 0 1 0 1.06Z" clipRule="evenodd" />
                    </svg>
                </button>

                <ul className={`${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition ease-in duration-100 absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`} tabIndex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">

                    {options.map((option) => (
                        <li onClick={() => {handleSelect(option)}} key={option.id} className={`hover:bg-sky-800 hover:text-white hover:outline-none relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900" id="listbox-option-0" role="option`}>
                            <span className="block truncate font-normal">{option.name}</span>

                        <span className={`${option.id === selected?.id ? 'visible' : 'hidden'} absolute inset-y-0 right-0 flex items-center pr-4 text-sky-800`}>
                            <svg className="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                                <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 0 1 .143 1.052l-8 10.5a.75.75 0 0 1-1.127.075l-4.5-4.5a.75.75 0 0 1 1.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 0 1 1.05-.143Z" clipRule="evenodd" />
                            </svg>
                            </span>
                        </li>
                    ))}

                </ul>
            </div>
        </div>

    )
}
